import React from "react";
import RequestBuy from "../components/common/requestForm/RequestBuy";
import RequestGetList from "../components/common/requestForm/RequestGetList";
import { FaRegEdit, FaRegFileAlt } from "react-icons/fa";
import { useState } from "react";
import Loading from "../components/common/Loading";
import CreateProductForm from "../components/common/forms/EditProductForm";
import CreateBrandForm from "../components/common/forms/CreateBrandForm";
import EditBrandForm from "../components/common/forms/EditBrandForm";
import ReactQuill from "react-quill";
import EditStory from "../components/common/forms/EditStory";
import CreateStory from "../components/common/forms/CreateStory";
import SendNotification from "../components/common/forms/SendNotification";

const NotificationManagePage = () => {
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [loading, setLoading] = useState();
  const [text, settext] = useState();

  async function handleCreateShow(params) {
    setCreating(true);
  }
  async function handleEditShow(params) {
    setEditing(true);
  }

  function handleBack() {
    setCreating(false);
    setEditing(false);
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full">
      <h1 className="title mb-10">مدیریت نوتیفیکشن ها</h1>

      <SendNotification />
    </div>
  );
};

export default NotificationManagePage;
