import React, { useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import VideoUpload from "./VideoUpload";
import Loading from "../Loading";
import { FaPlus } from "react-icons/fa";

const EditVideoBlog = () => {
  const [videos, setVideos] = useState();
  const [loading, setloading] = useState();
  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    setloading(true);
    await httpReauest("GET", "/blog/file/video", {}, {}).then(({ data }) => {
      setVideos(data?.data);
    });
    setloading(false);
  }

  async function handleCreate() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "POST",
      "/blog/file/video",
      {},
      { "x-access-token": user.token }
    ).then(({ data }) => {
      stayLogin();
    });
  }

  if (loading) {
    <Loading />;
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">
        ویدیو های بلاگ در موبایل
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
        {videos?.map((data) => (
          <VideoUpload key={data._id} data={data} stayLogin={stayLogin} />
        ))}
        <button
          className="flex justify-center items-center"
          onClick={handleCreate}
        >
          <FaPlus size={40} />
        </button>
      </div>
    </div>
  );
};

export default EditVideoBlog;
