import React from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../../config";
import { FaTrash } from "react-icons/fa";

const BlogCard = ({ data, setVehicles, select, vehicles }) => {
  async function handleAdd() {
    const repeat = vehicles?.find((e) => e._id === data?._id);
    if (!repeat) {
      setVehicles((cur) => [...cur, data]);
    }
  }

  async function handleDelete() {
    setVehicles((students) => students.filter((s) => s._id != data._id));
  }

  if (select) {
    return (
      <div className="p-2 relative border-brand">
        <div className="flex gap-3 items-center">
          <img
            src={CDN_BASE_URL + data?.cover}
            className="w-[65px] h-[65px] rounded-[10px] object-cover"
            alt=""
          />
          <p className="text-[24px] font-[700] ">{data?.name}</p>
        </div>
        <div className="mt-3">
          <p className="text-[14px] text-[#5C5F5F] three_line">{data?.desc}</p>
        </div>
        <FaTrash
          onClick={handleDelete}
          color="red"
          className="absolute left-1 top-1 cursor-pointer"
        />
      </div>
    );
  } else {
    return (
      <div onClick={handleAdd} className="p-2 cursor-pointer">
        <div className="flex gap-3 items-center">
          <img
            src={CDN_BASE_URL + data?.cover}
            className="w-[65px] h-[65px] rounded-[10px] object-cover"
            alt=""
          />
          <p className="text-[24px] font-[700] ">{data?.name}</p>
        </div>
        <div className="mt-3">
          <p className="text-[14px] text-[#5C5F5F] three_line">{data?.desc}</p>
        </div>
      </div>
    );
  }
};

export default BlogCard;
