import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";

const EditProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [vehicles, setVehicles] = useState();
  const [selectedVehicle, setselectedVehicle] = useState();
  const [loading, setloading] = useState();
  const [basic_details, setbasic_details] = useState([{ name: "", value: "" }]);
  const [details_info, setdetails_info] = useState([{ name: "", value: "" }]);
  const [previewCatalog, setpreviewCatalog] = useState();
  const [catalog, setCatalog] = useState();
  const [previewVideo, setPreviewVideo] = useState();
  const [video, setVideo] = useState();

  function handleAddBasicDetails() {
    formik.values.basic_details.push({ name: "", value: "" });
    setbasic_details([...basic_details, { name: "", value: "" }]);
  }
  function handleDeleteBasicDetails() {
    const newArr = basic_details.slice(0, basic_details.length - 1);
    setbasic_details(newArr);
    formik.setFieldValue("basic_details", newArr);
  }

  function handleAddDetailsInfo() {
    formik.values.details_info.push({ name: "", value: "" });
    setdetails_info([...details_info, { name: "", value: "" }]);
  }
  function handleDeleteDetailsInfo() {
    const newArr = details_info.slice(0, details_info.length - 1);
    setdetails_info(newArr);
    formik.setFieldValue("details_info", newArr);
  }
  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      category: Yup.string().required("الزامی است"),
    });
  };

  async function deleteImg(index) {
    setPreview((students) => students.filter((s, i) => i != index));
    setImages((students) => students.filter((s, i) => i != index));
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      desc: "",
      category: "",
      basic_details: [{ name: "", value: "" }],
      details_info: [{ name: "", value: "" }],
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("price", values.price);
      formdata.append("desc", values.desc);
      formdata.append("category", values.category);
      formdata.append("details", JSON.stringify(values.details_info));
      formdata.append("basic_details", JSON.stringify(values.basic_details));
      if (catalog) {
        formdata.append("catalog", catalog, catalog?.name);
      }
      if (video) {
        formdata.append("video", video, video?.name);
      }
      if (img) {
        formdata.append("img", img, img?.name);
      }

      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/product/" + selectedVehicle?._id, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول تغییر یافت");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setselectedVehicle(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
          setCatalog(null);
          setVideo(null);
        } else {
          setloading(false);
          toast.error(dat);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }
  function handleCatalog(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setCatalog(fils);
      createPreviewCatalog(fils);
    }
  }
  function handleVideo(e) {
    const fils = e.target.files[0];
    setVideo(fils);
    createPreviewVideo(fils);
  }

  async function getData() {
    setloading(true);

    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });

    await httpReauest("GET", "/product/names/dash", {}, {}).then(({ data }) => {
      setVehicles(data?.data);
    });
    setloading(false);
  }

  async function handleChange(e) {
    setImages([]);
    setPreview([]);
    const { data } = await httpReauest(
      "GET",
      "/product/" + e.target.value,
      {},
      {}
    );

    const newVehicle = data?.data;

    setselectedVehicle(newVehicle);
    formik.setFieldValue("name", newVehicle.name);
    formik.setFieldValue("price", newVehicle.price);
    formik.setFieldValue("desc", newVehicle.desc);
    formik.setFieldValue("category", newVehicle.category);
    formik.setFieldValue("basic_details", newVehicle.basic_details);
    formik.setFieldValue("details_info", newVehicle.details);
    setdetails_info(newVehicle.details);
    setbasic_details(newVehicle.basic_details);

    setPreviewImg(CDN_BASE_URL + newVehicle?.img);
    setPreviewVideo(CDN_BASE_URL + newVehicle?.video);
    setpreviewCatalog(CDN_BASE_URL + newVehicle?.catalog);
    newVehicle?.images?.map((e) => {
      toDataURL(CDN_BASE_URL + e)
        .then((dataUrl) => {
          var fileData = dataURLtoFile(dataUrl, e);
          setImages((current) => [...current, fileData]);
        })
        .catch((e) => {
          console.log(e);
        });
      setPreview((current) => [...current, CDN_BASE_URL + e]);
    });
  }

  async function handleDelete(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    await fetch(BASE_URL + "/product/" + selectedVehicle?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول حذف شد");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }
  async function createPreviewCatalog(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setpreviewCatalog(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }
  async function createPreviewVideo(videoInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewVideo(reader.result);
    };
    reader.readAsDataURL(videoInput);
  }
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <select
            id="vehicle"
            name="vehicle"
            onChange={handleChange}
            className={` w-full form-brand h-[48px]`}
          >
            <option>{loading && <Loading />}</option>
            {vehicles?.map((e) => {
              return <option value={e._id}>{e.name}</option>;
            })}
          </select>
          <p className="on-border font-bold">انتخاب محصول*</p>
        </span>
        {selectedVehicle && (
          <>
            <span className="relative">
              <input
                placeholder="مثلا : فوتون 450"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              {formik.errors.name && formik.touched.name && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.name}
                </small>
              )}
              <p className="on-border font-bold">نام محصول*</p>
            </span>
            <span className="relative">
              <input
                placeholder="مثلا : 12000"
                id="price"
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
             
              <p className="on-border font-bold">قیمت محصول*</p>
            </span>

            <span className="relative">
              <select
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={` w-full form-brand h-[48px]`}
              >
                <option></option>
                {categorys?.map((e) => (
                  <option value={e._id}>{e.name}</option>
                ))}
              </select>
              {formik.errors.category && formik.touched.category && (
                <small style={{ color: "red", fontSize: "14px" }}>
                  {formik.errors.category}
                </small>
              )}
              <p className="on-border font-bold">دسته بندی*</p>
            </span>

            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}

              <p className="on-border font-bold">تصویر اصلی</p>
            </label>
            <input
              onChange={(e) => {
                handleImg(e);
              }}
              type="file"
              className="hidden"
              id="img"
              accept="image/png, image/jpg, image/jpeg"
            />
            <label
              htmlFor="catalog"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewCatalog ? (
                <img
                  src={previewCatalog}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}

              <p className="on-border font-bold">کاتالوگ</p>
            </label>
            <input
              onChange={(e) => {
                handleCatalog(e);
              }}
              type="file"
              className="hidden"
              id="catalog"
              accept="image/png, image/jpg, image/jpeg"
            />
            <label
              htmlFor="video"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewVideo ? (
                <video
                  autoPlay
                  src={previewVideo}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus className="center" size={30} />
              )}

              <p className="on-border font-bold">فیلم محصول</p>
            </label>
            <input
              onChange={(e) => {
                handleVideo(e);
              }}
              type="file"
              className="hidden"
              id="video"
              accept="video/*"
            />
            <span dir="ltr" className="col-span-full">
              <h1 className="title text-right">:توضیح</h1>
              <ReactQuill
                value={formik.values.desc}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false], font: ["Opens Sans"] }, {}],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "code"],
                    ["clean"],
                  ],
                }}
                formats={formats}
                onChange={formik.handleChange}
              />
            </span>

            <span className="col-span-full">
              <h2 className="text-[18px]  font-bold px-3">
                مشخصات ساده (Basic Details)
              </h2>
            </span>
            {basic_details?.map((e, i) => (
              <span>
                {i + 1} :
                <span className="flex gap-2">
                  <input
                    placeholder="مثلا : Place of Origin"
                    id={`basic_details[${i}].name`}
                    name={`basic_details[${i}].name`}
                    value={formik.values?.basic_details[i]?.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full form-brand h-[48px]"
                  />
                  <input
                    placeholder="مثلا : China"
                    id={`basic_details[${i}].value`}
                    name={`basic_details[${i}].value`}
                    value={formik.values?.basic_details[i]?.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full form-brand h-[48px]"
                  />
                </span>
              </span>
            ))}
            <span className="items-center gap-3 flex">
              <FaPlus
                className="cursor-pointer"
                onClick={handleAddBasicDetails}
                size={30}
              />
              {basic_details.length > 1 && (
                <FaTrash
                  size={30}
                  color="red"
                  className="cursor-pointer"
                  onClick={handleDeleteBasicDetails}
                />
              )}
            </span>

            <span className="col-span-full mt-5">
              <h2 className="text-[18px]  font-bold px-3">مشخصات تخصصی</h2>
            </span>
            {details_info?.map((e, i) => (
              <span>
                {i + 1} :
                <span className="flex gap-2">
                  <input
                    placeholder="مثلا : Place of Origin"
                    id={`details_info[${i}].name`}
                    name={`details_info[${i}].name`}
                    value={formik.values?.details_info[i]?.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full form-brand h-[48px]"
                  />
                  <input
                    placeholder="مثلا : China"
                    id={`details_info[${i}].value`}
                    name={`details_info[${i}].value`}
                    value={formik.values?.details_info[i]?.value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full form-brand h-[48px]"
                  />
                </span>
              </span>
            ))}
            <span className="items-center gap-3 flex">
              <FaPlus
                className="cursor-pointer"
                onClick={handleAddDetailsInfo}
                size={30}
              />
              {details_info.length > 1 && (
                <FaTrash
                  size={30}
                  color="red"
                  className="cursor-pointer"
                  onClick={handleDeleteDetailsInfo}
                />
              )}
            </span>

            <label className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px]  border-[#444]">
              {previews?.length > 0 ? (
                <div
                  className="p-3"
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  {previews &&
                    previews.map((src, i) => {
                      return (
                        <span className="relative">
                          <img
                            src={src}
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                            alt=""
                          />
                          <FaTrash
                            color="red"
                            className="top-0 right-0 absolute"
                            onClick={() => deleteImg(i)}
                          />
                        </span>
                      );
                    })}
                  <label htmlFor="images" className="cursor-pointer">
                    <FaPlus size={30} />
                  </label>
                </div>
              ) : (
                <label htmlFor="images" className="center">
                  <FaPlus htmlFor="images" size={30} />
                </label>
              )}

              <p className="on-border font-bold">تصاویر</p>
            </label>
            <input
              onChange={(e) => {
                handleImages(e);
              }}
              type="file"
              className="hidden"
              id="images"
              accept="image/png, image/jpg, image/jpeg"
            />
            <span className="col-span-full flex gap-4">
              {loading ? (
                <Loading />
              ) : (
                <>
                  <button className="btn-brand">تغییر محصول</button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className=" btn-brand-outline"
                  >
                    {" "}
                    حذف محصول
                  </button>
                </>
              )}
            </span>
          </>
        )}
      </form>
    </div>
  );
};

export default EditProductForm;
