import React, { useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import ProductsCard from "../card/ProductsCard";
import ProductsCardSelect from "../card/ProductsCardSelect";
import { BASE_URL } from "../../../config";
import { toast } from "react-toastify";
import BlogCard from "../card/BlogCard";

const EditSliderBlogs = () => {
  const [vehcledata, setvehcledata] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    await httpReauest("GET", "/blog/slider", {}, {}).then(({ data }) => {
      setvehcledata(data?.data?.blogs);
    });

    await httpReauest("GET", "/blog", {}, {}).then(({ data }) => {
      setVehicles(data?.data);
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const arrat = [];

    await vehcledata?.map((e) => {
      arrat.push(e._id);
    });
    const user = JSON.parse(localStorage.getItem("user"));

    await httpReauest(
      "POST",
      "/blog/slider",
      { blogs: arrat },
      { "x-access-token": user.token }
    )
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("موفقیت آمیز");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }
  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم تغییر اسلایدر</h2>{" "}
      <form className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
        <span className="col-span-full border-brand p-4 relative grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-5">
          <p className="on-border font-bold">بلاگ های انتخاب شده</p>
          {vehcledata?.map((d) => {
            return <BlogCard select data={d} setVehicles={setvehcledata} />;
          })}
        </span>
        <span>
          <button onClick={handleSubmit} className="btn-brand">
            تایید
          </button>
        </span>
        <span className="col-span-full p-7 relative grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-5">
          <p className="on-border font-bold">انتخاب بلاگ برای اسلایدر</p>
          {vehicles?.map((d) => {
            return (
              <BlogCard
                data={d}
                setVehicles={setvehcledata}
                vehicles={vehcledata}
              />
            );
          })}
        </span>
      </form>
    </div>
  );
};

export default EditSliderBlogs;
