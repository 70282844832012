import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { httpReauest } from "../utils/httpRequest";
import { toast } from "react-toastify";
import { socket } from "../helpers/socketHelper";
import { FaAngleLeft, FaCircle, FaUser } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

const Chat = () => {
  const [visble, setVisble] = useState();
  const [content, setcontent] = useState();
  const [messages, setmessages] = useState([]);
  const [selected, setselected] = useState();
  const [covnerstaion, setcovnerstaion] = useState([]);
  const [ip, setip] = useState("admin");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
    const handleReceiveMessage = (senderId, content) => {
      const newMessage = {
        sender: senderId,
        content,
      };

      setmessages((c) => [...c, newMessage]);
      scrollToBottom();
    };

    socket.on("receive-message", handleReceiveMessage);

    return () => {
      socket.off("receive-message", handleReceiveMessage);
    };
  }, [socket]);

  async function getData(params) {
    await httpReauest("GET", "/message", {}, {}).then(async ({ data }) => {
      setcovnerstaion(data);

      if (searchParams.get("chat")) {
        const newCon = await data.find(
          (e) => e._id == searchParams.get("chat")
        );
        console.log(newCon);
        setselected(newCon);
        dataFrom(newCon.sender);
      }
    });
  }

  async function dataFrom(ips) {
    await httpReauest("GET", "/message/" + ips, {}, {})
      .then(({ data: data2 }) => {
        setmessages(data2);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const messageRef = useRef();

  useEffect(() => {
    if (messages) {
      scrollToBottom();
    }
  }, [messages]);

  const scrollToBottom = () => {
    messageRef.current?.scrollTo({ top: 9999999 });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    await httpReauest(
      "POST",
      "/message",
      { userId: selected?.sender, content, reciver: selected.sender },
      {}
    )
      .then(({ status }) => {
        if (status === 201) {
          setmessages((c) => [
            ...c,
            { content, sender: selected?.sender, reciver: selected.sender },
          ]);
          setcontent("");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });

    await socket.emit("send-message", selected.sender, content);
  }

  return (
    <div className="grid grid-cols-5 ">
      <div
        dir="rtl"
        className={`${
          selected ? "block" : "hidden lg:block"
        } h-[90vh] border-brand relative col-span-full  lg:col-span-4`}
      >
        {selected && (
          <>
            <div className="w-full border-b p-3 flex justify-between">
              {selected?.sender}
              <FaAngleLeft onClick={() => setselected(false)} />
            </div>
            <div
              ref={messageRef}
              className="flex flex-col gap-3 max-h-[80%] overflow-y-scroll  p-2"
            >
              {messages?.map((e) => {
                if (e.reciver == selected.sender) {
                  return (
                    <div className="w-fit p-3 bg-white border-brand rounded-[10px] max-w-[80%] me-auto text-right">
                      {e.content}
                    </div>
                  );
                } else {
                  return (
                    <div className="w-fit p-3 bg-black rounded-[10px] text-white max-w-[80%] ms-auto text-right">
                      {e.content}
                    </div>
                  );
                }
              })}
            </div>

            <form
              className="absolute flex justify-center items-center bottom-0 left-0 w-full"
              onSubmit={handleSubmit}
            >
              <input
                className="form-brand w-[80%]"
                onChange={(e) => setcontent(e.target.value)}
                value={content}
              />
              <button className="w-[20%] btn btn-brand" type="submit">
                ارسال
              </button>
            </form>
          </>
        )}
      </div>
      <div
        className={`${
          selected ? "hidden lg:block" : "block"
        } col-span-full lg:col-span-1 h-[90vh] border-brand`}
      >
        <h1 className="text-[18px] font-bold p-2">
          برای چت کاربر را انتخب کنید
        </h1>
        {covnerstaion?.map((c) => (
          <span
            onClick={() => {
              setselected(c);
              dataFrom(c.sender);
            }}
            className="w-full gap-5 p-3 flex border-b cursor-pointer relative"
          >
            <FaUser />
            <span>{c?.sender}</span>
            {c?.newMessage ? (
              <FaCircle color="green" className="absolute left-3" />
            ) : (
              <FaCircle color="gray" className="absolute left-3" />
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Chat;
