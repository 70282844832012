import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { FaTrash } from "react-icons/fa6";
import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from "react-quill";

const CreateProductForm = () => {
  const [img, setImg] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreview] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [categorys, setCategorys] = useState();
  const [loading, setloading] = useState();
  const [basic_details, setbasic_details] = useState([{ name: "", value: "" }]);
  const [details_info, setdetails_info] = useState([{ name: "", value: "" }]);
  const [previewCatalog, setpreviewCatalog] = useState();
  const [catalog, setCatalog] = useState();
  const [previewVideo, setPreviewVideo] = useState();
  const [video, setVideo] = useState();
  function handleAddBasicDetails() {
    formik.values.basic_details.push({ name: "", value: "" });
    setbasic_details([...basic_details, { name: "", value: "" }]);
  }
  function handleDeleteBasicDetails() {
    const newArr = basic_details.slice(0, basic_details.length - 1);
    setbasic_details(newArr);
  }

  function handleAddDetailsInfo() {
    formik.values.details_info.push({ name: "", value: "" });
    setdetails_info([...details_info, { name: "", value: "" }]);
  }
  function handleDeleteDetailsInfo() {
    const newArr = details_info.slice(0, details_info.length - 1);
    setdetails_info(newArr);
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      category: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      price: 0,
      category: "",
      basic_details: [{ name: "", value: "" }],
      details_info: [{ name: "", value: "" }],
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      if (!img) {
        return toast.error("لوگو نمی تواند خالی باشد");
      }
      formdata.append("name", values.name);
      formdata.append("price", values.price);
      formdata.append("desc", values.desc);
      formdata.append("category", values.category);
      formdata.append("img", img, img?.name);
      formdata.append("details", JSON.stringify(values.details_info));
      formdata.append("basic_details", JSON.stringify(values.basic_details));
      formdata.append("catalog", catalog, catalog?.name);
      formdata.append("video", video, video?.name);
      if (images) {
        for (let index = 0; index < images.length; index++) {
          formdata.append("images", images[index], images[index]?.name);
        }
      }

      await fetch(BASE_URL + "/product", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      }).then((dat) => {
        if (dat?.status == 201) {
          toast.success("محصول ساخته شد");
          setloading(false);
          formik.resetForm();
          setImg(null);
          setImages([]);
          setPreviewImg(null);
          setPreview([]);
          setCatalog(null);
          setVideo(null);
        } else {
          setloading(false);
          toast.error(dat);
        }
      });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  function handleCatalog(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setCatalog(fils);
      createPreviewCatalog(fils);
    }
  }
  function handleVideo(e) {
    const fils = e.target.files[0];
    setVideo(fils);
    createPreviewVideo(fils);
  }

  function handleImages(e) {
    const fils = e.target.files;
    if (fils) {
      for (let index = 0; index < fils?.length; index++) {
        if (fils[index].size > 2031517) {
          toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
        } else {
          setImages((current) => [...current, fils[index]]);
          setPreviwImages(fils[index]);
        }
      }
    }
  }

  async function setPreviwImages(i) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreview((current) => [...current, reader.result]);
    };
    reader.readAsDataURL(i);
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }
  async function createPreviewCatalog(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setpreviewCatalog(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }
  async function createPreviewVideo(videoInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewVideo(reader.result);
    };
    reader.readAsDataURL(videoInput);
  }

  async function getData() {
    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });
  }

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  function handleChange(value) {
    formik.setFieldValue("desc", value);
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد محصول</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            placeholder="مثلا : فوتون 450"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.name && formik.touched.name && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.name}
            </small>
          )}
          <p className="on-border font-bold">نام محصول*</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا :12000 "
            id="price"
            name="price"
            value={formik.values.peice}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          <p className="on-border font-bold">قیمت محصول*</p>
        </span>

        <span className="relative">
          <select
            id="category"
            name="category"
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={` w-full form-brand h-[48px]`}
          >
            <option></option>
            {categorys?.map((e) => (
              <option value={e._id}>{e.name}</option>
            ))}
          </select>
          {formik.errors.category && formik.touched.category && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.category}
            </small>
          )}
          <p className="on-border font-bold">دسته بندی*</p>
        </span>

        <label
          htmlFor="img"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewImg ? (
            <img
              src={previewImg}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">تصویر اصلی</p>
        </label>
        <input
          onChange={(e) => {
            handleImg(e);
          }}
          type="file"
          className="hidden"
          id="img"
          accept="image/png, image/jpg, image/jpeg"
        />
        <label
          htmlFor="catalog"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewCatalog ? (
            <img
              src={previewCatalog}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">کاتالوگ</p>
        </label>
        <input
          onChange={(e) => {
            handleCatalog(e);
          }}
          type="file"
          className="hidden"
          id="catalog"
          accept="image/png, image/jpg, image/jpeg"
        />
        <label
          htmlFor="video"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
        >
          {previewVideo ? (
            <video
              autoPlay
              src={previewVideo}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">فیلم محصول</p>
        </label>
        <input
          onChange={(e) => {
            handleVideo(e);
          }}
          type="file"
          className="hidden"
          id="video"
          accept="video/*"
        />
        <span dir="ltr" className="col-span-full">
          <h1 className="title text-right">:توضیح</h1>
          <ReactQuill
            value={formik.values.desc}
            modules={{
              toolbar: [
                [{ header: [1, 2, false], font: ["Opens Sans"] }, {}],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image", "code"],
                ["clean"],
              ],
            }}
            formats={formats}
            onChange={handleChange}
          />
        </span>
        {console.log(formik.values.desc)}

        <span className="col-span-full">
          <h2 className="text-[18px]  font-bold px-3">
            مشخصات ساده (Basic Details)
          </h2>
        </span>
        {basic_details?.map((e, i) => (
          <span>
            {i + 1} :
            <span className="flex gap-2">
              <input
                placeholder="مثلا : Place of Origin"
                id={`basic_details[${i}].name`}
                name={`basic_details[${i}].name`}
                value={formik.values.basic_details[i].name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              <input
                placeholder="مثلا : China"
                id={`basic_details[${i}].value`}
                name={`basic_details[${i}].value`}
                value={formik.values.basic_details[i].value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
            </span>
          </span>
        ))}
        <span className="items-center gap-3 flex">
          <FaPlus
            className="cursor-pointer"
            onClick={handleAddBasicDetails}
            size={30}
          />
          {basic_details.length > 1 && (
            <FaTrash
              size={30}
              color="red"
              className="cursor-pointer"
              onClick={handleDeleteBasicDetails}
            />
          )}
        </span>

        <span className="col-span-full mt-5">
          <h2 className="text-[18px]  font-bold px-3">مشخصات تخصصی</h2>
        </span>
        {details_info?.map((e, i) => (
          <span>
            {i + 1} :
            <span className="flex gap-2">
              <input
                placeholder="مثلا : Place of Origin"
                id={`details_info[${i}].name`}
                name={`details_info[${i}].name`}
                value={formik.values.details_info[i].name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
              <input
                placeholder="مثلا : China"
                id={`details_info[${i}].value`}
                name={`details_info[${i}].value`}
                value={formik.values.details_info[i].value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full form-brand h-[48px]"
              />
            </span>
          </span>
        ))}
        <span className="items-center gap-3 flex">
          <FaPlus
            className="cursor-pointer"
            onClick={handleAddDetailsInfo}
            size={30}
          />
          {details_info.length > 1 && (
            <FaTrash
              size={30}
              color="red"
              className="cursor-pointer"
              onClick={handleDeleteDetailsInfo}
            />
          )}
        </span>

        <label
          htmlFor="images"
          className="relative col-span-full w-full border-dashed border rounded-[10px] p-3 min-h-[340px] cursor-pointer border-[#444]"
        >
          {previews?.length > 0 ? (
            <div
              className="p-3"
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                display: "flex",
                flexWrap: "wrap",
                gap: "15px",
              }}
            >
              {previews &&
                previews.map((src) => {
                  return (
                    <img
                      src={src}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  );
                })}
              <FaPlus size={30} />
            </div>
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">تصاویر</p>
        </label>
        <input
          onChange={(e) => {
            handleImages(e);
          }}
          type="file"
          className="hidden"
          id="images"
          accept="image/png, image/jpg, image/jpeg"
        />
        {loading ? (
          <Loading />
        ) : (
          <button type="submit" className="btn-brand">
            ارسال
          </button>
        )}
      </form>
    </div>
  );
};

export default CreateProductForm;
