import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { httpReauest } from "../../../utils/httpRequest";
import { priceComma } from "../../../utils/priceComma";

const SendNotification = () => {
  const [img, setImg] = useState();
  const [img2, setImg2] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [previewImg2, setPreviewImg2] = useState();
  const [loading, setloading] = useState();
  const [categories, setcategories] = useState([]);
  const [vehicles, setvehicles] = useState([]);

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      title: Yup.string().required("الزامی است"),
      body: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      body: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();

      await httpReauest(
        "POST",
        "/notification/send",
        { title: values.title, body: values.body },
        { "x-access-token": user.token }
      )
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("نوتیفیکشن ارسال شد");
            setloading(false);
            formik.resetForm();
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد استوری</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            id="title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.title && formik.touched.title && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.title}
            </small>
          )}
          <p className="on-border font-bold">تایتل*</p>
        </span>

        <span className="relative">
          <input
            id="body"
            name="body"
            value={formik.values.body}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.body && formik.touched.body && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.body}
            </small>
          )}
          <p className="on-border font-bold">متن پیام*</p>
        </span>
        <span className="col-span-full">
          {loading ? <Loading /> : <button className="btn-brand">ارسال</button>}
        </span>
      </form>
    </div>
  );
};

export default SendNotification;
