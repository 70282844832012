import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import ReactQuill from "react-quill";
import { httpReauest } from "../../../utils/httpRequest";
import { Editor } from "@tinymce/tinymce-react";

const CreateBlogForm = () => {
  const [img, setImg] = useState();
  const [img2, setImg2] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [previewImg2, setPreviewImg2] = useState();
  const [loading, setloading] = useState();
  const [categorys, setCategorys] = useState();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setCategorys(data?.data);
    });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      author: Yup.string().required("الزامی است"),
      url: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      author: "",
      meta_keywords: "",
      meta_desc: "",
      content: "",
      desc: "",
      related: "",
      url: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("author", values.author);
      formdata.append("related", values.related);
      formdata.append("meta_keywords", values.meta_keywords);
      formdata.append("meta_desc", values.meta_desc);
      formdata.append("content", editorRef.current.getContent());
      formdata.append("desc", values.desc);
      formdata.append("url", values.url);
      formdata.append("cover", img2, img2?.name);

      await fetch(BASE_URL + "/blog", {
        method: "POST",
        body: formdata,
        headers: { "x-access-token": user.token },
      })
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("بلاگ ساخته شد");
            setloading(false);
            formik.resetForm();
            setImg(null);
            setImg2(null);
            setPreviewImg(null);
            setPreviewImg2(null);
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  function handleImg2(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg2(fils);
      setPreviwImage2(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function setPreviwImage2(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg2(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  const editorRef = useRef(null);

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد بلاگ</h2>

      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            placeholder="مثلا : دانتستی ها درباره فوتون 450"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.name && formik.touched.name && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.name}
            </small>
          )}
          <p className="on-border font-bold">نام بلاگ*</p>
        </span>
        <span className="relative">
          <input
            id="url"
            name="url"
            value={formik.values.url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.url && formik.touched.url && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.url}
            </small>
          )}
          <p className="on-border font-bold">url*</p>
        </span>
        <span className="relative">
          <input
            id="author"
            name="author"
            value={formik.values.author}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.author && formik.touched.author && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.author}
            </small>
          )}
          <p className="on-border font-bold">نام نویسنده*</p>
        </span>
        <span className="relative">
          <select
            id="related"
            name="related"
            value={formik.values.related}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          >
            <option></option>
            {categorys?.map((e) => (
              <option value={e._id}>{e.name}</option>
            ))}
          </select>
          {formik.errors.related && formik.touched.related && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.related}
            </small>
          )}
          <p className="on-border font-bold">مرتبط با دسته بندی</p>
        </span>
        <span className="relative">
          <input
            placeholder="مثلا : کامیون , کشنده , فوتون 450"
            id="meta_ketwords"
            name="meta_ketwords"
            value={formik.values.meta_ketwords}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          <p className="on-border font-bold">کلمات کلیدی*</p>
          {formik.errors.meta_ketwords && formik.touched.meta_ketwords && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.meta_ketwords}
            </small>
          )}
        </span>
        <span className="relative col-span-full">
          <textarea
            placeholder="مثلا : دانتستی ها درباره فوتون 450"
            id="meta_desc"
            name="meta_desc"
            value={formik.values.meta_desc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[88px]"
          />
          {formik.errors.meta_desc && formik.touched.meta_desc && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.meta_desc}
            </small>
          )}
          <p className="on-border font-bold">توضیح متا*</p>
        </span>
        <span className="relative col-span-full">
          <textarea
            placeholder="مثلا : دانتستی ها درباره فوتون 450"
            id="desc"
            name="desc"
            value={formik.values.desc}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[88px]"
          />
          {formik.errors.desc && formik.touched.desc && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.desc}
            </small>
          )}
          <p className="on-border font-bold">مقدمه</p>
        </span>
        <input
          onChange={(e) => {
            handleImg2(e);
          }}
          type="file"
          className="hidden"
          id="img2"
          accept="image/png, image/jpg, image/jpeg"
        />
        <label
          htmlFor="img2"
          className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444] col-span-full"
        >
          {previewImg2 ? (
            <img
              src={previewImg2}
              className="w-full h-full object-contain"
              alt=""
            />
          ) : (
            <FaPlus className="center" size={30} />
          )}

          <p className="on-border font-bold">کاور</p>
        </label>
        <input
          onChange={(e) => {
            handleImg(e);
          }}
          type="file"
          className="hidden"
          id="img"
          accept="image/png, image/jpg, image/jpeg"
        />
        <span className="col-span-full">
          <h1 className="title text-right">محتوا:</h1>
          <Editor
            apiKey="v0h6qza1g235gg4j50qhdidn1fu1zklnbft8dtbscqvzpzo7"
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              plugins:
                "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown",
              toolbar:
                "undo redo | blocks fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              mergetags_list: [
                { value: "First.Name", title: "First Name" },
                { value: "Email", title: "Email" },
              ],
              ai_request: (request, respondWith) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
            }}
          />
        </span>
        <span className="col-span-full">
          {loading ? <Loading /> : <button className="btn-brand">ارسال</button>}
        </span>
      </form>
    </div>
  );
};

export default CreateBlogForm;
