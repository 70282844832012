import React from "react";
import RequestBuy from "../components/common/requestForm/RequestBuy";
import RequestGetList from "../components/common/requestForm/RequestGetList";
import { FaPhotoVideo, FaRegEdit, FaRegFileAlt } from "react-icons/fa";
import { useState } from "react";
import Loading from "../components/common/Loading";
import CreateProductForm from "../components/common/forms/EditProductForm";
import CreateBrandForm from "../components/common/forms/CreateBrandForm";
import EditBrandForm from "../components/common/forms/EditBrandForm";
import Quill from "quill";
import CreateBlogForm from "../components/common/forms/CreateBlogForm";
import EditBlogForm from "../components/common/forms/EditBlogForm";
import EditTimerProduct from "../components/common/forms/EditTimerProduct";
import EditSliderBlogs from "../components/common/forms/EditSliderBlogs";
import EditVideoBlog from "../components/common/forms/EditVideoBlog";
import EditImgBlog from "../components/common/forms/EditImgBlog";

const BlogManagePage = () => {
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [slider, setslider] = useState();
  const [video, setvideo] = useState();
  const [img, setimg] = useState();
  const [loading, setLoading] = useState();

  async function handleCreateShow(params) {
    setCreating(true);
  }
  async function handleEditShow(params) {
    setEditing(true);
  }
  async function handleSlider(params) {
    setslider(true);
  }

  async function handleVideo(params) {
    setvideo(true);
  }

  async function handleImg(params) {
    setimg(true);
  }

  function handleBack() {
    setCreating(false);
    setEditing(false);
    setslider(false);
    setvideo(false);
    setimg(false);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full">
      <h1 className="title mb-10">مدیریت بلاگ ها</h1>
      {!editing && !creating && !slider && !video && !img && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <span className="col-span-full">
            <p className="text-center font-bold text-[28px]">
              چه کاری میخوای انجام بدی؟
            </p>
          </span>
          <span
            onClick={handleCreateShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">ایجاد بلاگ جدید</p>
            <FaRegFileAlt
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleEditShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              تغییر بلاگ های موجود
            </p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleSlider}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              تغییر بلاگ های روی اسلایدر
            </p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleVideo}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              بخش ویدیو های بلاگ در موبایل
            </p>
            <FaPhotoVideo
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>

          <span
            onClick={handleImg}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              بخش گالری بلاگ در موبایل
            </p>
            <FaPhotoVideo
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
        </div>
      )}

      {(editing || creating || slider || video || img) && (
        <>
          <button onClick={handleBack} className="btn-brand mb-10">
            بازگشت
          </button>
          <br />
        </>
      )}

      {creating && <CreateBlogForm />}
      {slider && <EditSliderBlogs />}
      {editing && <EditBlogForm />}
      {video && <EditVideoBlog />}
      {img && <EditImgBlog />}
    </div>
  );
};

export default BlogManagePage;
