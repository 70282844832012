import React, { useState } from "react";
import { FaCheck, FaCheckCircle, FaPlus, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { httpReauest } from "../../../utils/httpRequest";
import { BASE_URL, CDN_BASE_URL } from "../../../config";

const ImgUpload = ({ data, stayLogin }) => {
  const [img2, setImg2] = useState();
  const [previewImg2, setPreviewImg2] = useState();

  function handleImg2(e) {
    const fils = e.target.files[0];

    setImg2(fils);
    setPreviwImage2(fils);
  }

  async function setPreviwImage2(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg2(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function handleUpdate() {
    const user = JSON.parse(localStorage.getItem("user"));
    const formdata = new FormData();

    formdata.append("img", img2);
    formdata.append("id", data?._id);

    await fetch(BASE_URL + "/blog/file/img", {
      method: "PUT",
      body: formdata,
      headers: { "x-access-token": user.token },
    }).then(({ status }) => {
      if (status === 201) {
        toast.success("موفقیت آمیز بود");
        stayLogin();
      }
    });
  }

  async function handleDelete() {
    const user = JSON.parse(localStorage.getItem("user"));

    await fetch(BASE_URL + "/blog/file/" + data?._id, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    }).then(({ status }) => {
      if (status === 201) {
        toast.success("موفقیت آمیز بود");
        stayLogin();
      }
    });
  }

  return (
    <>
      <input
        onChange={(e) => {
          handleImg2(e);
        }}
        type="file"
        className="hidden"
        id={data?._id}
        accept="image/png, image/jpg, image/jpeg"
      />
      <label
        htmlFor={data?.file ? "" : data?._id}
        className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444] "
      >
        {data?.file ? (
          <img
            src={CDN_BASE_URL + data?.file}
            className="w-full h-full object-contain"
            alt=""
          />
        ) : previewImg2 ? (
          <img
            src={previewImg2}
            className="w-full h-full object-contain"
            alt=""
          />
        ) : (
          <FaPlus className="center" size={30} />
        )}

        <p className="on-border font-bold">تصویر</p>
        {!data?.file && previewImg2 && (
          <button
            onClick={handleUpdate}
            className="mt-5 z-10 w-full flex justify-center"
          >
            <FaCheckCircle color="green" size={30} />
          </button>
        )}

        {data?.file && (
          <button
            onClick={handleDelete}
            className="mt-5 z-10 w-full flex justify-center"
          >
            <FaTrashAlt color="red" size={30} />
          </button>
        )}
      </label>
    </>
  );
};

export default ImgUpload;
