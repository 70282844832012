import React, { useEffect, useState } from "react";
import { httpReauest } from "../../../utils/httpRequest";
import { BASE_URL, CDN_BASE_URL } from "../../../config";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";
import Loading from "../Loading";
import { priceComma } from "../../../utils/priceComma";

const EditStory = () => {
  const [brands, setBrands] = useState();
  const [storys, setstorys] = useState();
  const [categories, setcategories] = useState();
  const [img, setImg] = useState();
  const [img2, setImg2] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [previewImg2, setPreviewImg2] = useState();
  const [loading, setloading] = useState();
  const [selectedBrand, setselectedBrand] = useState();
  const [vehicles, setvehicles] = useState([]);

  useEffect(() => {
    stayLogin();
  }, []);

  async function stayLogin() {
    await httpReauest("GET", "/category", {}, {}).then(({ data }) => {
      setcategories(data?.data);
    });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      category: Yup.string().required("الزامی است"),
      vehicle: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      category: "",
      vehicle: "",
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const formdata = new FormData();
      if (img) {
        formdata.append("img", img, img?.name);
      }
      formdata.append("category", values.category);
      formdata.append("vehicle", values.vehicle);

      await fetch(BASE_URL + "/story/" + selectedBrand, {
        method: "PUT",
        body: formdata,
        headers: { "x-access-token": user.token },
      })
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("استوری تغییر یافت");
            setloading(false);
            formik.resetForm();
            setselectedBrand(null);
            setstorys(null);
            setImg(null);
            setImg2(null);
            setPreviewImg(null);
            setPreviewImg2(null);
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  async function handleDelete(params) {
    const user = JSON.parse(localStorage.getItem("user"));
    await fetch(BASE_URL + "/story/" + selectedBrand, {
      method: "DELETE",
      headers: { "x-access-token": user.token },
    })
      .then((dat) => {
        if (dat?.status == 201) {
          toast.success("استوری حذف شد");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }

  async function handleChange(e) {
    formik.setFieldValue("category", e.target.value);
    await httpReauest("GET", "/story?category=" + e.target.value, {}, {}).then(
      ({ data }) => {
        setstorys(data.data);
      }
    );

    await httpReauest(
      "GET",
      "/vehicle?category=" + e.target.value,
      {},
      {}
    ).then(({ data }) => {
      setvehicles(data?.data);
    });
  }

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  function handleImg2(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg2(fils);
      setPreviwImage2(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function setPreviwImage2(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg2(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم استوری</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        {!selectedBrand && (
          <span className="relative">
            <select
              id="category"
              name="category"
              onChange={handleChange}
              className={` w-full form-brand h-[48px]`}
            >
              <option></option>
              {categories?.map((e) => {
                if (!e.end) {
                  return <option value={e._id}>{e.name}</option>;
                }
              })}
            </select>
            <p className="on-border font-bold">انتخاب دسته بندی*</p>
          </span>
        )}

        {!selectedBrand && storys && (
          <>
            <span className="col-span-full">
              استوری های موجود :(برای تغیر کلیک کنید)
            </span>

            {storys?.map((e) => (
              <span
                onClick={() => {
                  setselectedBrand(e._id);
                  formik.setFieldValue("vehicle", e.vehicle._id);
                }}
                className="relative w-full border-dashed border rounded-[10px] p-3 h-[500px] cursor-pointer border-[#444]"
              >
                <img
                  src={CDN_BASE_URL + e.img}
                  className="w-full h-full object-cover"
                  alt=""
                />
                <p className="on-border font-bold">{e.vehicle.name}</p>
              </span>
            ))}
          </>
        )}

        {selectedBrand && (
          <>
            {vehicles.length != 0 && (
              <>
                <span className="relative">
                  <select
                    id="vehicle"
                    name="vehicle"
                    value={formik.values.vehicle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={` w-full form-brand h-[48px]`}
                  >
                    <option></option>
                    {vehicles?.map((e) => {
                      if (!e.end) {
                        return (
                          <option value={e._id}>
                            {e.name} {priceComma(e.price * 1000000000, 2)}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <p className="on-border font-bold">انتخاب محصول مربوط*</p>
                </span>
                <label
                  htmlFor="img"
                  className="relative w-full border-dashed border rounded-[10px] p-3 h-[500px] cursor-pointer border-[#444]"
                >
                  {previewImg ? (
                    <img
                      src={previewImg}
                      className="w-full h-full object-contain"
                      alt=""
                    />
                  ) : (
                    <FaPlus className="center" size={30} />
                  )}
                  <p className="on-border font-bold">تصویر جدید برای استوری</p>
                </label>

                <input
                  onChange={(e) => {
                    handleImg(e);
                  }}
                  type="file"
                  className="hidden"
                  id="img"
                  accept="image/png, image/jpg, image/jpeg"
                />
                <span className="col-span-full flex gap-4">
                  {loading ? (
                    <Loading />
                  ) : (
                    <>
                      <button className="btn-brand">تغییر استوری</button>
                      <button
                        type="button"
                        onClick={handleDelete}
                        className=" btn-brand-outline"
                      >
                        {" "}
                        حذف استوری
                      </button>
                    </>
                  )}
                </span>
              </>
            )}
          </>
        )}
      </form>
    </div>
  );
};

export default EditStory;
