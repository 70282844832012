import React from "react";
import RequestBuy from "../components/common/requestForm/RequestBuy";
import RequestGetList from "../components/common/requestForm/RequestGetList";
import { FaRegEdit, FaRegFileAlt } from "react-icons/fa";
import { useState } from "react";
import Loading from "../components/common/Loading";
import CreateProductForm from "../components/common/forms/EditProductForm";
import CreateBrandForm from "../components/common/forms/CreateBrandForm";
import EditBrandForm from "../components/common/forms/EditBrandForm";
import ReactQuill from "react-quill";
import CreateCategoryForm from "../components/common/forms/CreateCategoryForm";
import EditCategoryForm from "../components/common/forms/EditCategoryForm";

const CategoryManagePage = () => {
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [loading, setLoading] = useState();
  const [text, settext] = useState();

  async function handleCreateShow(params) {
    setCreating(true);
  }
  async function handleEditShow(params) {
    setEditing(true);
  }

  function handleBack() {
    setCreating(false);
    setEditing(false);
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full">
      <h1 className="title mb-10">مدیریت دسته بندی ها</h1>
      {!editing && !creating && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <span className="col-span-full">
            <p className="text-center font-bold text-[28px]">
              چه کاری میخوای انجام بدی؟
            </p>
          </span>
          <span
            onClick={handleCreateShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              ایجاد دسته بندی جدید
            </p>
            <FaRegFileAlt
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
          <span
            onClick={handleEditShow}
            className="border-brand p-5 flex justify-between items-center hover:bg-black hover-text-secendory cursor-pointer duration-300"
          >
            <p className="text-center font-bold text-[20px]">
              تغییر دسته بندی های موجود
            </p>
            <FaRegEdit
              size={45}
              color="#000"
              className="bg-white p-1 rounded-[10px]"
            />
          </span>
        </div>
      )}

      {(editing || creating) && (
        <>
          <button onClick={handleBack} className="btn-brand mb-10">
            بازگشت
          </button>
          <br />
        </>
      )}

      {creating && <CreateCategoryForm />}
      {editing && <EditCategoryForm />}
    </div>
  );
};

export default CategoryManagePage;
