import { useFormik } from "formik";
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { BASE_URL } from "../../../config";
import Loading from "../Loading";
import { httpReauest } from "../../../utils/httpRequest";

const CreateAdminForm = () => {
  const [img, setImg] = useState();
  const [img2, setImg2] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [previewImg2, setPreviewImg2] = useState();
  const [loading, setloading] = useState();

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      email: Yup.string().required("الزامی است"),
      password: Yup.string().required("الزامی است"),
      name: Yup.string().required("الزامی است"),
      phone: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      phone: "",
      blogs: false,
      forms: false,
      brands: false,
      products: false,
      chat: false,
      story: false,
      category: false,
    },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      setloading(true);
      const user = JSON.parse(localStorage.getItem("user"));

      await httpReauest("POST", "/admin/create", values, {
        "x-access-token": user.token,
      })
        .then((dat) => {
          if (dat?.status == 201) {
            toast.success("ادمین ساخته شد");
            setloading(false);
            formik.resetForm();
          }
        })
        .catch((err) => {
          setloading(false);
          toast.error(err.response?.data?.message);
        });
    },
  });

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }
  function handleImg2(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg2(fils);
      setPreviwImage2(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function setPreviwImage2(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg2(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  return (
    <div>
      <h2 className="text-[20px] mb-8 font-bold p-3">فرم ایجاد ادمین</h2>
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
      >
        <span className="relative">
          <input
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.email && formik.touched.email && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.email}
            </small>
          )}
          <p className="on-border font-bold">نام کاربری*</p>
        </span>
        <span className="relative">
          <input
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.password && formik.touched.password && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.password}
            </small>
          )}
          <p className="on-border font-bold">رمز عبور*</p>
        </span>
        <span className="relative">
          <input
            id="name"
            name="name"
            type="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.name && formik.touched.name && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.name}
            </small>
          )}
          <p className="on-border font-bold">اسم*</p>
        </span>
        <span className="relative">
          <input
            id="phone"
            name="phone"
            type="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full form-brand h-[48px]"
          />
          {formik.errors.phone && formik.touched.phone && (
            <small style={{ color: "red", fontSize: "14px" }}>
              {formik.errors.phone}
            </small>
          )}
          <p className="on-border font-bold">شماره تلفن*</p>
        </span>
        <span className="col-span-full flex gap-8 flex-wrap">
          <p className="w-full text-[18px]">سطح دسترسی ها:</p>
          <span className="flex justify-between items-start gap-3">
            <span className="text-[#2E3132] text-[16px] font-[500]">
              مدیریت فرم ها
            </span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                id="forms"
                name="forms"
                value={formik.values.forms}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                class="sr-only peer"
              />
              <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
            </label>
          </span>
          <span className="flex justify-between items-start gap-3">
            <span className="text-[#2E3132] text-[16px] font-[500]">
              مدیریت محصولات
            </span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                id="products"
                name="products"
                value={formik.values.products}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                class="sr-only peer"
              />
              <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
            </label>
          </span>
          <span className="flex justify-between items-start gap-3">
            <span className="text-[#2E3132] text-[16px] font-[500]">
              مدیریت دسته بندی ها
            </span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                id="category"
                name="category"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                class="sr-only peer"
              />
              <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
            </label>
          </span>
          <span className="flex justify-between items-start gap-3">
            <span className="text-[#2E3132] text-[16px] font-[500]">
              مدیریت برند ها
            </span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                id="brands"
                name="brands"
                value={formik.values.brands}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                class="sr-only peer"
              />
              <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
            </label>
          </span>
          <span className="flex justify-between items-start gap-3">
            <span className="text-[#2E3132] text-[16px] font-[500]">
              چت با کاربر
            </span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                id="chat"
                name="chat"
                value={formik.values.chat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                class="sr-only peer"
              />
              <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
            </label>
          </span>
          <span className="flex justify-between items-start gap-3">
            <span className="text-[#2E3132] text-[16px] font-[500]">
              مدیریت بلاگ ها
            </span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                id="blogs"
                name="blogs"
                value={formik.values.blogs}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                class="sr-only peer"
              />
              <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
            </label>
          </span>
          <span className="flex justify-between items-start gap-3">
            <span className="text-[#2E3132] text-[16px] font-[500]">
              مدیریت استوری ها
            </span>
            <label class="inline-flex items-center cursor-pointer">
              <input
                id="story"
                name="story"
                value={formik.values.story}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="checkbox"
                class="sr-only peer"
              />
              <div class="relative w-11 h-[25px] bg-white peer-focus:outline-none rounded-full peer border peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full border-black after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-black after:border-gray-300 after:border after:rounded-full after:h-[19px] after:w-[19px] after:transition-all dark:border-gray-600 after:peer-checked:bg-white peer-checked:bg-black"></div>
            </label>
          </span>
        </span>
        <span className="col-span-full">
          {loading ? <Loading /> : <button className="btn-brand">ارسال</button>}
        </span>
      </form>
    </div>
  );
};

export default CreateAdminForm;
